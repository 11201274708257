import { Toast } from 'bootstrap';

export function getByID(id: string): HTMLElement {
    const element = document.getElementById(id);
    if (element === null) {
        throw new Error(`Element with ID '${id}' not found`);
    }
    return element;
}

export function hideElement(element: HTMLElement): void {
    element.style.display = 'none';
}

export function showElement(element: HTMLElement): void {
    if (element.style.display !== 'none') return;
    element.style.display = '';
}

export function isIOS() {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

export function showErrorToast(message: string) {
    const toast = getByID('toast');
    const toastBody = getByID('toast-body');
    toastBody.innerText = message;
    Toast.getOrCreateInstance(toast).show();
}
