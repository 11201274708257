export async function lockScreenOrientation() {
    try {
        // @ts-expect-error Only available in some browsers
        await screen.orientation.lock('landscape');
    } catch (e) {
        console.warn('Device does not support screen.orientation.lock');
        return false;
    }
}

export function unlockScreenOrientation() {
    try {
        screen.orientation.unlock();
    } catch (e) {
        console.warn('Device does not support screen.orientation.unlock');
        return false;
    }
}

export function onScreenOrientationChange(callback: (e: Event) => void) {
    screen.orientation.addEventListener('change', callback);
}

export function rotateContentToLandscape() {
    const type = screen.orientation.type;
    if (type === 'landscape-primary') {
        document.body.style.transform = 'rotate(0deg)';
    } else if (type === 'landscape-secondary') {
        document.body.style.transform = 'rotate(180deg)';
    } else if (type === 'portrait-secondary') {
        document.body.style.transform = 'rotate(90deg)';
    } else if (type === 'portrait-primary') {
        document.body.style.transform = 'rotate(-90deg)';
    }
}

export async function openFullscreen() {
    try {
        if (document.documentElement.requestFullscreen) {
            await document.documentElement.requestFullscreen();
            // @ts-expect-error Safari polyfill
        } else if (document.documentElement.webkitRequestFullscreen) {
            // @ts-expect-error Safari polyfill
            await document.documentElement.webkitRequestFullscreen();
            // @ts-expect-error IE11 polyfill
        } else if (document.documentElement.msRequestFullscreen) {
            // @ts-expect-error IE11 polyfill
            await document.documentElement.msRequestFullscreen();
            // @ts-expect-error Firefox polyfill
        } else if (document.documentElement.mozRequestFullScreen) {
            // @ts-expect-error Firefox polyfill
            await document.documentElement.mozRequestFullScreen();
        }
    } catch (err) {
        if (err instanceof Error) {
            console.warn(err.message);
        }
    }
}

export async function closeFullscreen() {
    try {
        if (document.exitFullscreen) {
            await document.exitFullscreen();
            // @ts-expect-error Safari polyfill
        } else if (document.webkitExitFullscreen) {
            // @ts-expect-error Safari polyfill
            await document.webkitExitFullscreen();
            // @ts-expect-error IE11 polyfill
        } else if (document.msExitFullscreen) {
            // @ts-expect-error IE11 polyfill
            await document.msExitFullscreen();
            // @ts-expect-error Firefox polyfill
        } else if (document.mozExitFullscreen) {
            // @ts-expect-error Firefox polyfill
            await document.mozExitFullscreen();
        }
    } catch (err) {
        if (err instanceof Error) {
            console.warn(err.message);
        }
    }
}

export function onFullScreenChange(callback: (e: Event) => void) {
    document.addEventListener('fullscreenchange', callback);
    document.addEventListener('webkitfullscreenchange', callback);
    document.addEventListener('msfullscreenchange', callback);
    document.addEventListener('mozfullscreenchange', callback);
}

export function isFullscreen(): boolean {
    return document.fullscreenElement !== null;
}

export function isScreenPortrait(): boolean {
    if (!screen || !screen.orientation || !screen.orientation.type) {
        if (window.innerWidth > window.innerHeight) {
            return false;
        }
        return true;
    }
    return screen.orientation.type.includes('portrait');
}
