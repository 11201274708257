import { getByID } from './helpers';
import { getLanguage } from './mode';
import { getPOIs } from './pois';

export async function translateInfoScreen() {
    const lang = getLanguage();
    const infoScreen = getByID('infoScreen');
    const elements = infoScreen.querySelectorAll('[data-i18n]');
    for (const element of elements) {
        const content = element.getAttribute(`data-${lang}`);
        element.innerHTML = content === null ? '' : content;
    }
    const pois = getPOIs();
    const stationsContainer = getByID('stations-container');
    stationsContainer.innerHTML = '';
    for (const poi of pois) {
        if (!poi.title) {
            continue;
        }
        stationsContainer.innerHTML += `<li>${poi.title[lang as 'de' | 'nl' | 'en']}</li>`;
    }
}
