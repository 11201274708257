/* eslint-disable security/detect-object-injection */
import L from 'leaflet';
import 'leaflet-rastercoords';
import { getPOIs } from './pois';
import { getByID, showElement } from './helpers';
import { getLanguage } from './mode';
import { isScreenPortrait } from './screen';

let map: L.Map;

export function renderMap() {
    if (map) {
        map.remove();
    }
    const deviceWidth = isScreenPortrait() ? window.innerHeight : window.innerWidth;

    let initialZoom = 2.8;

    if (deviceWidth < 700) {
        initialZoom = 1.25;
    } else if (deviceWidth < 1000) {
        initialZoom = 1.5;
    } else if (deviceWidth < 1500) {
        initialZoom = 2;
    }

    map = L.map('map', {
        zoomSnap: 0.25,
        attributionControl: false,
        zoomDelta: 0.25,
        zoom: initialZoom,
        zoomControl: false,
    });

    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    map.dragging.disable();

    const img = [
        7680, // original width of image
        4320, // original height of image
    ];

    const rc = new L.RasterCoords(map, img);
    map.setMaxZoom(rc.zoomLevel());
    map.setView(rc.unproject([img[0] / 2, img[1] / 2]), initialZoom);

    L.tileLayer('/img/tiles/{z}/{x}/{y}.png', {
        attribution: undefined,
        id: 'base',
        noWrap: true,
        bounds: rc.getMaxBounds(),
        maxNativeZoom: rc.zoomLevel(),
        minZoom: 1.5,
    }).addTo(map);

    const currentLang = getLanguage() as 'de' | 'en' | 'nl';

    const pois = getPOIs();
    for (const poi of pois) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Wrong type definition
        const icon = L.icon(poi.icon);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Works :D
        const marker = L.marker(rc.unproject(poi.coords), { icon: icon }).addTo(map);
        if (poi.title && poi.vidUrl) {
            marker.addEventListener('click', () => {
                if (window.innerWidth < 1900) {
                    getByID('startBtnVideoTitle').innerText = poi.title[currentLang].replace('Station ', '').replace('0', '');
                } else {
                    getByID('startBtnVideoTitle').innerText = poi.title[currentLang].replace('0', '');
                }
                const startBtn = getByID('startVideoBtn');
                showElement(startBtn);
                startBtn.dataset.vi = pois.indexOf(poi).toString();
            });
        } else {
            marker.setZIndexOffset(-1000);
        }
    }
}

export function getPOIByIndex(vi: number) {
    return getPOIs()[vi];
}
