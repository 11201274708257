import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { getByID, hideElement, isIOS, showElement } from './helpers';
import { isFullscreen } from './screen';

let player: Plyr;

export function showVideo(
    title: string,
    sources: {
        src: string;
        type: string;
        size: number;
    }[],
) {
    hideElement(getByID('mapScreen'));
    showElement(getByID('videoScreen'));

    const controls = ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'title', 'fullscreen'];

    player = new Plyr('#player', {
        controls: controls,
        blankVideo: '/videos/blank.mp4',
        storage: { enabled: false },
        fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true,
            container: '#videoScreen',
        },
        autoplay: true,
        clickToPlay: true,
    });

    player.source = {
        type: 'video',
        title: title,
        sources: sources,
    };

    player.on('playing', () => {
        player.fullscreen.enter();
    });

    const videoTitleEle = getByID('videoTitle');
    videoTitleEle.innerText = title;
    showElement(videoTitleEle);

    const backBtn = getByID('videoBackBtn');
    showElement(backBtn);

    player.on('exitfullscreen', () => {
        player.destroy();
        hideElement(getByID('videoScreen'));
        if (isFullscreen() || isIOS()) {
            showElement(getByID('mapScreen'));
        }
    });

    document.getElementsByTagName('video')[0].addEventListener('webkitendfullscreen', () => {
        player.destroy();
        hideElement(getByID('videoScreen'));
        if (isFullscreen() || isIOS()) {
            showElement(getByID('mapScreen'));
        }
    });

    player.on('ended', () => {
        if (player.fullscreen.active) {
            player.fullscreen.exit();
        }
        setTimeout(() => player.destroy(), 50);
        hideElement(getByID('videoScreen'));
        showElement(getByID('mapScreen'));
    });

    player.on('controlshidden', () => {
        hideElement(videoTitleEle);
        hideElement(backBtn);
    });

    player.on('controlsshown', () => {
        showElement(videoTitleEle);
        showElement(backBtn);
    });
}

export function destroyVideoScreen() {
    if (player.fullscreen.active) {
        player.fullscreen.exit();
    }
    setTimeout(() => player.destroy(), 50);
    hideElement(getByID('videoScreen'));
    if (isFullscreen() || isIOS()) {
        showElement(getByID('mapScreen'));
    }
}
