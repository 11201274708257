import { getVideoServerURL } from './network';

const namesChild = ['Einleitung', 'Geschichte', 'Altar', 'Orgel', 'Architektur_aussen', 'Architektur_innen', 'Religionsgeschichte', 'Menschen'];
const namesAdult = [
    'Einführung',
    'Geschichte_Tieben',
    'Altar_Biebersdorf',
    'Orgel',
    'Architektur_aussen',
    'Architektur_innen',
    'Religionsgeschichte',
    'Menschen',
];

function getVideoUrls(id: number, lang: 'de' | 'en' | 'nl') {
    const filledID = id.toString().padStart(2, '0');
    const langUrl = lang === 'de' ? 'german' : lang;
    const vidServer = getVideoServerURL();
    return {
        adult: `${vidServer}${langUrl}/adult/Station_${filledID}_${namesAdult[id - 1]}.mp4`,
        child: `${vidServer}${langUrl}/child/Station_${filledID}_${namesChild[id - 1]}.mp4`,
    };
}

export function getPOIs() {
    return [
        {
            icon: {
                iconUrl: '/img/desk-marker-64.png',
                iconSize: [40, 40],
            },
            coords: {
                x: 3370,
                y: 3024,
            },
        },
        {
            icon: {
                iconUrl: '/img/restroom-marker-64.png',
                iconSize: [40, 40],
            },
            coords: {
                x: 6280,
                y: 2908,
            },
        },
        {
            icon: {
                iconUrl: '/img/1-circle-fill.png',
                iconSize: [60, 60],
            },
            coords: {
                x: 3754,
                y: 2937,
            },
            title: { de: 'Station 01: Einführung', en: 'Station 01: Introduction', nl: 'Station 01: Inleiding' },
            vidUrl: {
                de: getVideoUrls(1, 'de'),
                en: getVideoUrls(1, 'en'),
                nl: getVideoUrls(1, 'nl'),
            },
        },
        {
            icon: {
                iconUrl: '/img/2-circle-fill.png',
                iconSize: [60, 60],
            },
            coords: {
                x: 5850,
                y: 3336,
            },
            title: { de: 'Station 02: Geschichte', en: 'Station 02: History', nl: 'Station 02: Geschiedenis' },
            vidUrl: {
                de: getVideoUrls(2, 'de'),
                en: getVideoUrls(2, 'en'),
                nl: getVideoUrls(2, 'nl'),
            },
        },
        {
            icon: {
                iconUrl: '/img/3-circle-fill.png',
                iconSize: [60, 60],
            },
            coords: {
                x: 3602,
                y: 1741,
            },
            title: { de: 'Station 03: Altar', en: 'Station 03: Altar', nl: 'Station 03: Altaar' },
            vidUrl: {
                de: getVideoUrls(3, 'de'),
                en: getVideoUrls(3, 'en'),
                nl: getVideoUrls(3, 'nl'),
            },
        },
        {
            icon: {
                iconUrl: '/img/4-circle-fill.png',
                iconSize: [60, 60],
            },
            coords: {
                x: 2336,
                y: 1470,
            },
            title: { de: 'Station 04: Orgel', en: 'Station 04: Orgel', nl: 'Station 04: Organ' },
            vidUrl: {
                de: getVideoUrls(4, 'de'),
                en: getVideoUrls(4, 'en'),
                nl: getVideoUrls(4, 'nl'),
            },
        },
        {
            icon: {
                iconUrl: '/img/5-circle-fill.png',
                iconSize: [60, 60],
            },
            coords: {
                x: 7084,
                y: 2768,
            },
            title: { de: 'Station 05: Außenarchitektur', en: 'Station 05: Exterior architecture', nl: 'Station 05: Exterieur architectuur' },
            vidUrl: {
                de: getVideoUrls(5, 'de'),
                en: getVideoUrls(5, 'en'),
                nl: getVideoUrls(5, 'nl'),
            },
        },
        {
            icon: {
                iconUrl: '/img/6-circle-fill.png',
                iconSize: [60, 60],
            },
            coords: {
                x: 4404,
                y: 1316,
            },
            title: { de: 'Station 06: Innenarchitektur', en: 'Station 06: Interior architecture', nl: 'Station 06: Interieur architectuur' },
            vidUrl: {
                de: getVideoUrls(6, 'de'),
                en: getVideoUrls(6, 'en'),
                nl: getVideoUrls(6, 'nl'),
            },
        },
        {
            icon: {
                iconUrl: '/img/7-circle-fill.png',
                iconSize: [60, 60],
            },
            coords: {
                x: 4448,
                y: 1912,
            },
            title: { de: 'Station 07: Religionsgeschichte', en: 'Station 07: History of religion', nl: 'Station 07: Geschiedenis van de religie' },
            vidUrl: {
                de: getVideoUrls(7, 'de'),
                en: getVideoUrls(7, 'en'),
                nl: getVideoUrls(7, 'nl'),
            },
        },
        {
            icon: {
                iconUrl: '/img/8-circle-fill.png',
                iconSize: [60, 60],
            },
            coords: {
                x: 2428,
                y: 593,
            },
            title: { de: 'Station 08: Menschen', en: 'Station 08: Humans', nl: 'Station 08: Mensen' },
            vidUrl: {
                de: getVideoUrls(8, 'de'),
                en: getVideoUrls(8, 'en'),
                nl: getVideoUrls(8, 'nl'),
            },
        },
    ];
}
