export function getSupportedLanguages(): string[] {
    return ['de', 'en', 'nl'];
}

export function getLanguage(): string {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage !== null) {
        return storedLanguage;
    }

    if (typeof navigator.language === 'string') {
        const preferredLanguage = navigator.language.includes('-') ? navigator.language.split('-')[0] : navigator.language;
        if (getSupportedLanguages().includes(preferredLanguage.toLowerCase())) {
            return preferredLanguage;
        }
    }

    return import.meta.env.PUBLIC_DEFAULT_LANG;
}

export function setLanguage(language: string): void {
    if (!getSupportedLanguages().includes(language)) {
        throw new Error(`Language '${language}' not supported`);
    }
    localStorage.setItem('language', language);
}

export function getAgeGroup() {
    const storedAgeGroup = localStorage.getItem('ageGroup');
    if (storedAgeGroup !== null) {
        return storedAgeGroup;
    }

    return 'adult';
}

export function setAgeGroup(ageGroup: string): void {
    if (!['adult', 'child'].includes(ageGroup)) {
        throw new Error(`Age group '${ageGroup}' not supported`);
    }
    localStorage.setItem('ageGroup', ageGroup);
}
