const vidOnlineUrl = import.meta.env.PUBLIC_VIDEO_ONLINE_URL;
const vidLocalUrl = import.meta.env.PUBLIC_VIDEO_LOCAL_URL;
let vidBaseUrl = vidOnlineUrl;

export async function detectVideoServerURL(): Promise<string> {
    const localServer = await checkS3Server(vidLocalUrl);
    if (localServer) {
        vidBaseUrl = vidLocalUrl;
    } else {
        const remoteServer = await checkS3Server(vidOnlineUrl);
        if (!remoteServer) {
            throw new Error('No video server found');
        }
    }
    console.info('Using video server: ' + vidBaseUrl);
    return vidBaseUrl;
}

export function getVideoServerURL() {
    return vidBaseUrl;
}

async function checkS3Server(url: string) {
    try {
        const result = await fetchWithTimeout(url + 'check.txt');
        if (result.status === 200) {
            const txt = await result.text();
            if (txt === '509cdb12c87e9d3e1992800c5fba26f3') {
                return true;
            }
        }
        return false;
    } catch (error) {
        return false;
    }
}

async function fetchWithTimeout(resource: RequestInfo, options: RequestInit & { timeout?: number } = {}) {
    const { timeout = 2500 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);

    return response;
}
